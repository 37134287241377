import React from 'react'
import { LayoutIR } from '../../../components/organisms/Layout/LayoutIR'
import { HeadingTertiary } from '../../../components/atoms/Heading/HeadingTertiary'
import { SEOIR } from '../../../components/data/SEO'
import { ENGLISH_TITLE } from '../../../components/data/English'

const Page = () => {
  return (
    <LayoutIR
      heading="株式基本情報"
      headingEnglish={ENGLISH_TITLE.StockInformation}
      BreadcrumbData={[
        { title: '株主・投資家の皆さまへ', url: '/ir' },
        { title: '株式・株主情報', url: '/ir/stock' },
        { title: '株式基本情報', url: '/' },
      ]}
    >
      <div className="pc:pt-12 pc:px-8 pc:mb-80 pc:pb-12 bg-white">
        <HeadingTertiary label="株式数・株主数" />
        <div className="mt-6 pc:mt-6 flex pr-6 pl-4 py-6 pc:py-8 border-t border-b border-border10">
          <p className="text-sm pc:text-md w-28 pc:w-48 leading-normal font-bold">
            発行可能株式数
          </p>
          <p className="text-sm pc:text-md leading-normal">21,810,000株</p>
        </div>
        <div className="flex pr-6 pl-4 py-6 pc:py-8 border-b border-border10">
          <p className="text-sm pc:text-md w-28 pc:w-48 leading-normal font-bold">
            発行済株式数
          </p>
          <p className="text-sm pc:text-md leading-normal">5,482,750株</p>
        </div>
        <div className="flex pr-6 pl-4 py-6 pc:py-8 border-b border-border10">
          <p className="text-sm pc:text-md w-28 pc:w-48 leading-normal font-bold">
            単元株式数
          </p>
          <p className="text-sm pc:text-md leading-normal">100株</p>
        </div>
        <p className="text-sm pt-2 pc:pt-6 text-right">2022年3月31日現在</p>
      </div>
    </LayoutIR>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => (
  <SEOIR title="株式基本情報" url="/ir/stock/information" />
)
